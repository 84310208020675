import ErrorPage from '../shared/error/ErrorPage';
import React from 'react';
import LinksProvider from "../services/http/LinksProvider";
import {withPageWrapper} from "../services/utils/withPageWrapper";
import PageLink from "../components/common/page-link/PageLink";

const namespacesRequired = ['common', "error-pages"];

const Error = (props) => {

  const Error404 = ({t}) => {
    return (<ErrorPage title={t('error-pages:error-404.title')}
                       desc={t('error-pages:error-404.desc')}
                       principal={props.principal}
                       background={'/static/images/errors/404.jpg'}>
      <PageLink href={LinksProvider.ROUTES.HOME}>
        <a className="btn__primary">{t('error-pages:error-404.cta')}</a>
      </PageLink>
    </ErrorPage>);
  };

  const Error500 = ({t}) => {
    return (<ErrorPage title={t('error-pages:error-500.title')}
                       desc={t('error-pages:error-500.desc')}
                       principal={props.principal}
                       background={'/static/images/errors/500.jpg'}/>);
  };

  return (<React.Fragment>
    {props.statusCode === 404 && (<Error404 {...props}/>)}

    {props.statusCode === 500 && (<Error500 {...props}/>)}
  </React.Fragment>);
};

Error.getInitialProps = async (ctx) => {
  if (ctx && ctx.res && ctx.res.statusCode) {
    return {statusCode: ctx.res.statusCode, namespacesRequired};
  }

  return {statusCode: 500};
};

export default withPageWrapper({namespacesRequired})(Error);
