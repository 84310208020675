import React from 'react';
import LayoutLanding from "../layout/LayoutLanding";
import css from './Error.module.scss';
import Heading from "../textes/Heading/Heading";

const ErrorPage = (props) => {
  return (
    <LayoutLanding principal={props.principal}>
      <div className={css.error} style={{backgroundImage: `url('${props.background}')`}}>
        <div className={css.error__content}>
          <Heading headerContent={props.title} customClass={css.error__title}></Heading>
          <p dangerouslySetInnerHTML={{__html: props.desc}}></p>

          {props.children}
        </div>
      </div>
    </LayoutLanding>
  );
};

export default ErrorPage;
